import { useKeycloak } from '@react-keycloak/web';
import { Outlet, createRootRoute, useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect } from 'react';
import { OpenAPI as CatalogAPI } from '../services/catalog';
import { OpenAPI as WarehouseAPI } from '../services/warehouse';

export const Route = createRootRoute({
	component: RootComponent,
});

function RootComponent() {
	// Utilize the useKeycloak hook to manage authentication state.
	const { keycloak, initialized } = useKeycloak();
	const navigate = useNavigate();

	// Defines a function to initiate login process using Keycloak.
	const login = useCallback(() => {
		keycloak.login().catch(console.error);
	}, [keycloak]);

	// Effect hook to automatically trigger login if user is not authenticated.
	useEffect(() => {
		if (initialized && !keycloak.authenticated) {
			login();
		}
	}, [initialized, keycloak.authenticated, login, navigate]);

	// Set Tokens for different APIs from keycloak.
	CatalogAPI.TOKEN = keycloak.token;
	WarehouseAPI.TOKEN = keycloak.token;

	// Displays a loading message until the Keycloak is initialized and token is available.
	if (!initialized || !keycloak.token) {
		return <div>Loading...</div>;
	}
	return <Outlet />;
}
