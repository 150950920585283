import { createFileRoute } from '@tanstack/react-router';
import { Spinner } from '@lbc-toolkit/spinner';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Route = createFileRoute('/')({
	component: Index,
});

function Index() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate('/components');
	}, [navigate]);

	return <Spinner spin />;
}
