import './styling/app.css';

import { ToastContainer } from '@lbc-toolkit/toast';
import { ToolkitProvider } from '@lbc-toolkit/toolkit-provider';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { FormatIcu } from '@tolgee/format-icu';
import { DevTools, Tolgee, TolgeeProvider } from '@tolgee/react';
import Keycloak from 'keycloak-js';
import { Provider } from 'react-redux';
import Config from './configuration/config';
import { store } from './context/store';
import { routeTree } from './routeTree.gen';
import { OpenAPI as CatalogAPI } from './services/catalog';
import { OpenAPI as WarehouseAPI } from './services/warehouse';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientTokens } from './models/keycloak-types';

function App() {
	CatalogAPI.BASE = Config.CatalogServerUrl ?? '';
	WarehouseAPI.BASE = Config.WarehouseServerUrl ?? '';

	const tolgee = Tolgee().use(DevTools()).use(FormatIcu()).init({
		apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
		apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
		defaultLanguage: 'de',
	});

	const router = createRouter({ routeTree });

	const _kc = new Keycloak({
		url: process.env.REACT_APP_KEYCLOAK_URL,
		realm: process.env.REACT_APP_KEYCLOAK_REALM ?? '',
		clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
	});

	// Defines a function to log events from the Keycloak authentication service.
	// @param event - The event object from Keycloak.
	// @param error - The error object from Keycloak, if any.

	const eventLogger = (event: unknown, error: unknown) => {
		console.log('onKeycloakEvent', event, error);
	};

	// Defines a function to log token updates from the Keycloak authentication service.
	// @param tokens - The current token object from Keycloak.
	const tokenLogger = (tokens: AuthClientTokens) => {
		console.log('onKeycloakTokens', tokens);
		CatalogAPI.TOKEN = tokens.token;
		WarehouseAPI.TOKEN = tokens.token;
	};

	return (
		<Provider store={store}>
			<TolgeeProvider tolgee={tolgee} fallback='Loading...'>
				<ReactKeycloakProvider authClient={_kc} onEvent={eventLogger} onTokens={tokenLogger}>
					<div className='App'>
						<ToastContainer position='top-right' width='408px' />
						<ToolkitProvider>
							<RouterProvider router={router} />
						</ToolkitProvider>
					</div>
				</ReactKeycloakProvider>
			</TolgeeProvider>
		</Provider>
	);
}

export default App;
